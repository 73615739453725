import React from "react"
import clsx from "clsx"
import cn from "classnames"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"

import * as styles from "./banner.module.scss"
import { useObserver } from "../../../hooks/useObserver"
import GetFriendName from "./GetFriendName"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

type BannerProps = {
  darkColor?: boolean
  buttonText?: string
  description?: string
  orderNum?: string
}

const defaultDescription = `
Получите до 1000 баллов кэшбэка на карту «Халва»
`

export function Banner({
  darkColor,
  buttonText = "Оформить карту",
  description = defaultDescription,
  orderNum,
}: BannerProps) {
  const isVisible = useObserver(["app", "calculatorCard", "reviewCard", "moreBenefits", "formPkw"])
  return (
    <>
      <section data-exclude={orderNum}>
        <Container className={cn(styles.container, darkColor && styles.darkColor)}>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <GetFriendName />
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={clsx(styles.btnBlock, { [styles.hideMobBlock]: isVisible })}>
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
