import React from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import { handleClickBanner } from "../../helpers/WatcherDL/utils/handleClickBanner"
import * as styles from "./howGetHalva.module.scss"

type Props = {
  orderNum?: string
  buttonText?: string
}

export function HowGetHalva({ orderNum, buttonText = "Оформить карту" }: Props) {
  return (
    <section data-exclude={orderNum} className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.secondContainer}>
          <Img alt="bg image" className={styles.img} />
          <div className={styles.text}>
            <h2 className={styles.head}>
              Получите карту «Халва» <br />
              удобным способом
            </h2>
            <ul className={styles.subText}>
              <li>
                – Бесплатно доставим курьером
                <br />
                на следующий день
              </li>
              <li>– В ближайшем отделении банка</li>
            </ul>

            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </div>
      </Container>
    </section>
  )
}
