import getSearchParam from "@ecom/ui/utils/getSearchParam"

export const urlDataConfig = (url: string) =>
  getSearchParam("utm_medium") === "Partner" &&
  getSearchParam("utm_source") === "postomat_halva" &&
  getSearchParam("utm_campaign") === "invitefriend" &&
  getSearchParam("utm_content") === `${url}` &&
  getSearchParam("utm_term") === "qrcode"

export const urlDataWithCodeConfig = (code: string) =>
  getSearchParam("utm_medium") === "Partner" &&
  getSearchParam("utm_source") === "postomat_halva" &&
  getSearchParam("utm_campaign") === "invitefriend" &&
  getSearchParam("utm_content") === "qrcode" &&
  getSearchParam("code") === code
