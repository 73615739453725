import React, { useEffect, useState } from "react"
import sendRequest from "@ecom/ui/utils/sendRequest"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import clsx from "clsx"
import { urlDataConfig, urlDataWithCodeConfig } from "./urlDataConfig"

import * as styles from "./banner.module.scss"

export default function GetFriendName() {
  const [friend, setFriendName] = useState("Ваш друг")
  const [urlName, setUrlName] = useState(false)
  const clientId = getSearchParam("code")

  useEffect(() => {
    if (
      urlDataConfig("bg-Petropavlovsk-Kamchatsky") ||
      urlDataConfig("bg-Vladivostok-1") ||
      urlDataWithCodeConfig("5968589764")
    ) {
      setUrlName(true)
    }
  }, [urlName])

  useEffect(() => {
    if (clientId === 324595596) {
      setFriendName("Woman_halva.club")
    } else {
      sendRequest(`${process.env.GATSBY_APP_HALVA_API_URL}/v1/cache/client/name`, {
        variable: clientId,
      })
        .then((res: { status: string; firstName: string }) => {
          if (res.status === "success") {
            let { firstName } = res
            firstName = firstName[0].toUpperCase() + firstName.slice(1)
            setFriendName(firstName)
          } else {
            throw new Error("Failed to fetch name")
          }
        })
        .catch((e: Error) => {
          console.error(e)
          setFriendName("Ваш друг")
        })
    }
  }, [clientId])

  return (
    <>
      {urlName ? (
        <div className={clsx(styles.title, styles.titleSecond)}>
          <h1>Оформите бесплатную карту Халва и делайте покупки с еще большим удовольствием</h1>
        </div>
      ) : (
        <div className={styles.title}>
          <h1>
            <span>{friend} рекомендует вам оформить карту «Халва»!</span>
          </h1>
        </div>
      )}
    </>
  )
}
