// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--lxTLG";
export var btnBlock = "banner-module--btnBlock--ibpja";
export var container = "banner-module--container--U+my3";
export var darkColor = "banner-module--darkColor--gbgtz";
export var hideMobBlock = "banner-module--hideMobBlock--Y++wm";
export var img = "banner-module--img--Cny10";
export var mobBtn = "banner-module--mobBtn--Z+1P+";
export var red = "banner-module--red--3bfvq";
export var secondContainer = "banner-module--secondContainer--1VU4S";
export var text = "banner-module--text--v2D7L";
export var title = "banner-module--title--aWnqQ";